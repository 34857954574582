<template>
  <div
    class="surface-ground px-2 md:px-6 lg:px-8 flex align-items-center justify-content-center min-h-screen">
    <div
      class="surface-card p-4 shadow-2 border-round w-full sm:w-7 md:w-6 lg:w-4">
      <div class="text-center mb-3">
        <img
          src="@/assets/pb-consig.png"
          alt="Image"
          width="70%"
          class="mb-5" />
        <div class="text-600 text-2xl font-medium">{{ titulo }}</div>
      </div>

      <form novalidate @submit.prevent="validate(!v$.$invalid)">
        <label for="username" class="block text-900 font-medium">CPF</label>
        <InputText
          id="username"
          v-model="v$.username.$model"
          type="text"
          class="w-full"
          :class="{ 'p-invalid': v$.username.$invalid && submitted }"
          required />
        <small v-if="v$.username.$invalid && submitted" class="p-error"
          >Usuário é obrigatório</small
        >

        <label for="password" class="mt-3 block text-900 font-medium"
          >Senha</label
        >
        <InputText
          id="password"
          v-model="v$.password.$model"
          type="password"
          class="w-full"
          :class="{ 'p-invalid': v$.password.$invalid && submitted }"
          required
          name="password" />
        <small v-if="v$.password.$invalid && submitted" class="p-error"
          >Senha é obrigatória</small
        >

        <div class="flex align-items-center justify-content-between mb-2">
          <div class="flex align-items-center">
            <a
              class="font-medium no-underline text-blue-500 text-right cursor-pointer"
              target="_blank"
              :href="recuperarEmail">
              PRIMEIRO ACESSO
            </a>
          </div>
          <a
            class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
            target="_blank"
            :href="resetLink">
            Recuperar senha!
          </a>
        </div>
        <div class="text-lg mb-2">
          <div class="">
            ATENÇÃO: Por favor, redefina sua senha clicando em
            <strong>"Recuperar senha!"</strong>.
          </div>

          <div class="mt-2">
            Caso não tenha e-mail cadastrado, clique em
            <strong>"PRIMEIRO ACESSO"</strong>.
          </div>
        </div>

        <Button class="w-full" type="submit" :disabled="loading">
          <span v-if="!loading" class="pi pi-user"></span>
          <span v-if="loading" class="pi pi-spin pi-spinner"></span>
          <span class="p-button-label">Acessar</span>
        </Button>
      </form>
    </div>

    <Toast />
  </div>
</template>

<script>
import axios from 'axios'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Config from '@/config'
import RoleService from '@/service/RoleService'

export default {
  name: 'LoginIndex',

  props: {
    titulo: {
      type: String,
      default: '',
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      http_login: null,
      loading: false,
    }
  },

  computed: {
    recuperarEmail: () => {
      return `${Config.PRIMEIRO_ACESSO_URL}`
    },

    resetLink: () => {
      return `${Config.REALM_PB}/login-actions/reset-credentials?client_id=${Config.CLIENT_ID}`
    },
  },

  created() {
    this.http_login = axios.create({
      baseURL: Config.REALM_PB,
    })
    this.rolesService = new RoleService(this.$http)
  },

  validations() {
    return {
      username: { required },
      password: { required },
    }
  },

  methods: {
    validate(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Usuário ou senha inválido(s).',
          life: 10000,
        })
        return
      }
      this.submit()
    },

    async parseJwt(token) {
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join(''),
      )
      return JSON.parse(jsonPayload)
    },

    async submit() {
      this.loading = true
      var qs = require('qs')
      var user = qs.stringify({
        client_id: Config.CLIENT_ID,
        username: this.username,
        password: this.password,
        grant_type: 'password',
      })

      try {
        const { data } = await this.http_login.post(
          '/protocol/openid-connect/token',
          user,
        )
        var jsonparsed = await this.parseJwt(data.access_token)
        if (!jsonparsed.resource_access.pbconsig) {
          throw {
            response: {
              data: { error_description: 'Usuário sem permissões.' },
            },
          }
        }
        this.success(
          data,
          jsonparsed.resource_access.pbconsig.roles,
          jsonparsed,
        )
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$toast.add({
          severity: 'error',
          summary: this.getErrorMessage(error),
          life: 10000,
        })
      }
    },

    async success(data, roles, jsonparsed) {
      const usuario = {
        email: jsonparsed.email,
        primeironome: jsonparsed.given_name,
        sobrenome: jsonparsed.family_name,
        nomecompleto: jsonparsed.name,
        usuario: jsonparsed.preferred_username,
        uuid: jsonparsed.sub,
      }

      await this.$auth.login({
        user: usuario,
        token: data.access_token,
        refresh_token: data.refresh_token,
        roles: roles,
        expires_in: data.expires_in,
      })

      try {
        const rolesBanco = await this.rolesService.rolesDoUsuarioLogado()
        if (rolesBanco) {
          if (this.$auth.roles) {
            this.$auth.roles = this.$auth.roles.concat(rolesBanco)
          } else {
            this.$auth.roles = rolesBanco
          }
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Não foi possível carregar as permissões.',
          life: 10000,
        })
      }

      this.$router.push('/').catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Acesso não autorizado a este módulo.',
          life: 10000,
        })
      })
    },

    getErrorMessage(error) {
      if (error.response) {
        switch (error.response.data.error_description) {
          case 'Account is not fully set up':
            return 'Usuário com restrição. Entre em contato com a gestão do PBConsig.'
          case 'Invalid user credentials':
            return 'Usuário ou senha inválido(s)'
          case 'Account disabled':
            return 'Conta desativada.'
          default:
            return error.response.data.error_description
        }
      }
      return 'Erro ao realizar login. Verifique sua conexão.'
    },
  },
}
</script>

<style lang="scss" scoped></style>
